import {Component, ViewChild} from '@angular/core';
import {ModalController, NavParams, LoadingController, Platform, IonContent, AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {CommunicationService, ModalService} from '@caregiver/providers';
import {timer, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {User} from '@caregiver/providers';
import * as moment from 'moment';
import {ResponseChat, InjectedChat} from '@hrs/interfaces';
import {HttpErrorResponse} from '@angular/common/http';
import {UntypedFormControl, UntypedFormGroup, UntypedFormBuilder} from '@angular/forms';
import {getLogger} from '@hrs/logging';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.page.html',
})
export class ChatPage {
  private readonly logger = getLogger('ChatPage');
  userHrsId: string;
  patientHrsId: string;
  messages: (ResponseChat | InjectedChat)[] = [];
  data: UntypedFormGroup;
  textMessagesLoaded;
  messageStatus: string;
  poll: any;
  polling: boolean;
  chatroomId: number;
  getMessagesEvent: Subscription;
  openNewChatEvent: Subscription;
  @ViewChild(IonContent, {}) content: IonContent;

  constructor(
      private platform: Platform,
      private communication: CommunicationService,
      private loadingCtrl: LoadingController,
      private translateService: TranslateService,
      private navParams: NavParams,
      private modalCtrl: ModalController,
      private modalService: ModalService,
      private alertCtrl: AlertController,
      private formBuilder: UntypedFormBuilder
  ) {
      this.userHrsId = User.hrsid || null;
      this.patientHrsId = User.patientHrsId || null;
      this.chatroomId = User.chatroomId || this.navParams.get('chatroomId');
      this.modalService.setModalStatus('ChatMessagesModalPage', true, this.patientHrsId);
  }

  ngOnInit() {
      if (this.chatroomId) { // chatroomId will not exist for new caregivers
          this.getTextMessages();
      }
      this.getMessagesEvent = this.communication.getChatNewMessage$.subscribe(() => {
          this.getTextMessages(true);
      });

      this.openNewChatEvent = this.communication.exitChatOpenNew$.subscribe(() => {
          this.dismiss();
      });

      this.data = this.formBuilder.group({
          message: new UntypedFormControl('', null)
      });
  }

  ionViewDidLeave() {
      this.stopPolling();
      this.getMessagesEvent.unsubscribe();
      this.openNewChatEvent.unsubscribe();
  }

  /**
   * Gets all of the messages for the patient
   * Incoming represents a new chat received via firebase through Comms Service event notificationsn while the chat modal is open
   * we don't want to show a loader if a new message is received while the modal is open
   */
  async getTextMessages(incoming?: boolean) {
      if (!incoming) {
          this.loadingCtrl.create({
              message: this.translateService.instant('LOADING'),
          });
      }

      this.communication.getTextMessages(this.userHrsId, this.patientHrsId, this.chatroomId).subscribe(
          {
              next: (res: ResponseChat[]) => {
                  this.messages = res;
                  if (!incoming) {
                      this.pollGetTexts();
                  }
              },
              error: (err: HttpErrorResponse) => {
                  this.handleGetTextMessagesError(err, incoming);
              },
              complete: () => {
                  if (!incoming) {
                      this.loadingCtrl.dismiss();
                  }
              }
          }
      );
  }

  async handleGetTextMessagesError(err: HttpErrorResponse, incoming?: boolean) {
      this.logger.phic.error('Error: ', err);
      let alert = await this.alertCtrl.create({
          header: this.translateService.instant('ERROR_TITLE'),
          message: this.translateService.instant('GET_MESSAGE_ERROR'),
          buttons: [
              {
                  text: this.translateService.instant('CANCEL_BUTTON'),
                  role: 'cancel',
                  handler: () => {}
              }, {
                  text: this.translateService.instant('RETRY_BUTTON'),
                  handler: () => {
                      this.getTextMessages(incoming);
                  }
              }
          ]
      });
      return await alert.present();
  }

  /**
   * Poll get chat endpoint
   * Hitting chat endpoint every thirty seconds anytime the a chat is in view
   */
  pollGetTexts(): void {
      const poll = timer(30000, 30000).pipe(
          switchMap(() => this.communication.getTextMessages(this.userHrsId, this.patientHrsId, this.chatroomId))
      );

      this.poll = poll
          .subscribe((res: any) => {
              this.polling = true;
              const messages = res;
              if (this.messages.length < messages.length) {
              // the new response has more messages than the previous response
              // add to dom and scroll to bottom
                  this.polling = false;
                  this.messages = messages;
                  this.callScrollFunction();
              }
          });
  }

  stopPolling(): void {
      if (this.poll) {
          this.poll.unsubscribe();
      }
  }

  /**
   * Convert time and date into a consistent format
   */
  convertTimeAndDate(value: string): string {
      return moment(value, 'YYYY-MM-DDTHH:mm:ssZ').local().format('MM/DD/YYYY h:mm:ss A');
  }

  /**
   * Scrolls to bottom of chat message list
   */
  callScrollFunction(): void {
      this.content.scrollToBottom(0);
  }

  /**
   * Posts the new message written by the caregiver to the patient
   */
  sendTextMessage(): void {
      let message: InjectedChat = {
          hrsid: this.userHrsId,
          message: this.data.value.message,
          messageStatus: this.translateService.instant('SENDING')
      };

      this.messages.push(message);
      this.callScrollFunction();
      this.communication.sendTextMessage(this.data.value.message, this.userHrsId, this.patientHrsId, this.chatroomId).subscribe(
          {
              next: (res: {data: ResponseChat}) => {
                  message.messageStatus = this.translateService.instant('SENT');
                  let updatedMessage = {
                      message: ''
                  };
                  this.data.setValue(updatedMessage, {emitEvent: true});
                  if (!this.chatroomId) {
                      this.chatroomId = res.data.chatroomId;
                      User.chatroomId = res.data.chatroomId;
                  }
              },
              error: (err: HttpErrorResponse) => {
                  this.logger.phic.error('Error: ', err);
                  message.messageStatus = this.translateService.instant('FAILED_TO_SEND');
                  this.handleSendTextMessageError(message);
              }
          }
      );
  }

  async handleSendTextMessageError(message: InjectedChat) {
      let alert = await this.alertCtrl.create({
          header: this.translateService.instant('ERROR_TITLE'),
          message: this.translateService.instant('SEND_MESSAGE_ERROR'),
          buttons: [
              {
                  text: this.translateService.instant('CANCEL_BUTTON'),
                  role: 'cancel'
              }, {
                  text: this.translateService.instant('RETRY_BUTTON'),
                  handler: () => {
                      this.messages = this.messages.filter((msg) => {
                          return (JSON.stringify(msg) !== JSON.stringify(message));
                      });
                      this.sendTextMessage();
                  },
              },
          ]
      });
      return await alert.present();
  }

  dismiss(): void {
      this.modalCtrl.dismiss();
      this.modalService.setModalStatus('ChatMessagesModalPage', false, null);
  }
}

