import {Injectable} from '@angular/core';

/**
 * A simple settings/config class for storing key/value pairs with persistence.
 */
@Injectable({
    providedIn: 'root',
})
export class Settings {
  private SETTINGS_KEY: string = '_settings';

  private settings: any = {};
  _defaults: any;

  constructor() {
      this._defaults = {name: 'CaregiverConnectMobile'};
  }

  load(): Promise<void> {
      let value = this.getValue(this.SETTINGS_KEY);
      if (value) {
          this.settings = value;
          return Promise.resolve(this._mergeDefaults(this._defaults));
      } else {
          return Promise.resolve(this.setAll(this._defaults));
      }
  }

  _mergeDefaults(defaults: any): void {
      for (let k in defaults) {
          if (!(k in this.settings)) {
              this.settings[k] = defaults[k];
          }
      }
      return this.setAll(this.settings);
  }

  merge(settings: any) {
      for (let k in settings) {
          if (k) {
              this.settings[k] = settings[k];
          }
      }
      return this.save();
  }

  setValue(key: string, value: any): void {
      this.settings[key] = value;
  }

  setAll(value: any) {
      return this.setValue(this.SETTINGS_KEY, value);
  }

  getValue(key: string) {
      return this.settings[key];
  }

  save() {
      return this.setAll(this.settings);
  }

  get allSettings() {
      return this.settings;
  }
}
