import {Injectable} from '@angular/core';
import {GatewayApi, CommunicationService} from '@hrs/providers';
import {FirebaseAccountInfo} from '../../../../firebase/firebase-account-info';
import {getLogger} from '@hrs/logging';
import {Observable, share, Subscription} from 'rxjs';
import {FirebaseMessaging, INotificationPayload} from 'cordova-plugin-firebase-messaging';
import {FirebaseMessagingService} from './firebase-messaging.service';

import {User} from '../user/user';
@Injectable({
    providedIn: 'root',
})

export class FirebaseNotifications {
    private readonly logger = getLogger('FirebaseNotifications');
    data: any;
    userHrsId: string;
    shouldUpdateToken: boolean;
    static notification: any;
    private onTokenRefreshSubscription: Subscription;
    private onNotificationSubscription: Subscription;
    private user: User;
    private readonly fcm = FirebaseMessaging;

    constructor(
        private communication: CommunicationService,
        private gatewayApi: GatewayApi,
        private firebaseMessagingService: FirebaseMessagingService
    ) {}

    /**
     * Manually Ask for Push Permission (IOS ONLY)
     * Once user answers yes/no, they will not be asked again
     */
    public askIOSPushPermission(): void {
        this.logger.debug(`askIOSPushPermission()`);
        this.fcm.requestPushPermission().then((permissionGranted) => {
            if (permissionGranted) {
                this.logger.phic.debug('Push Permission Granted');
            } else {
                this.logger.phic.debug('Push Permission Declined');
            }
        }, (err) => {
            this.logger.phic.error('Failed to request push permission', err);
        });
    }

    /**
     * Create Channel on Device for Firebase to send Notifications to
     */
    public createAndroidNotificationChannel(): void {
        this.logger.debug(`createAndroidNotificationChannel()`);
        this.fcm.createNotificationChannel({
            id: 'default-channel-id',
            name: 'Default channel',
            importance: 'high',
            visibility: 'public'
        }).then((res) => {
            this.logger.phic.debug('Successfully created Notification Channel');
        }, (err) => {
            this.logger.phic.error('Failed to create Notification Channel', err);
        });
    }

    /**
     * Set CGM to chosen Firebase account.
     *
     * Only available to HRSTAB.
     * This is because we disable FirebaseInitProvider in AndroidManifestTablet.xml, which prevents PCM from automatically initializing
     * the FirebaseApp from google-services.json and allows us to initialize manually with the creds of our choosing.
     */
    public setFirebaseAccount(account: string): Promise<void> {
        this.logger.debug(`setFirebaseAccount() account = ${account}`);
        const accountInfo = FirebaseAccountInfo[account];
        return new Promise((resolve, reject) => {
            this.fcm.initDifferentAccount(
                accountInfo
            ).then(() => {
                this.logger.phic.info(`Successfully initialized ${account} Firebase account.`);
                resolve();
            },
            (err) => {
                const message = `Failed to initialize ${account} FirebaseApp.`;
                this.logger.phic.error(message, err);
                reject(new Error(message));
            });
        });
    }

    /**
     * Subscribe to Firebase topics
     * Get firebase device token
     * @param response
     */
    public initializeFirebase(user: User) {
        this.logger.debug(`initializeFirebase()`);
        this.user = user;
        this.shouldUpdateToken = true;
        // Subscribe to topic and get device's current registration id
        this.fcm.subscribeToTopic('all').then( (res) => {
            this.logger.phic.debug('Successfully subscribed to firebase topics');
        }, (err) => {
            this.logger.phic.error('Failed to subscribe to firebase topics', err);
        });
        // get firebase device token
        this.fcm.getToken().then((token) => {
            this.logger.phic.debug('Firebase token: ' + token);
            // store video call firebase token
            this.updateToken(token);
            this.buildNotificationHandler();
        }, (err) => {
            this.logger.phic.error('Failed to get firebase token', err);
            // this is a common error returned from firebase which usually indicates the device has no internet connection
            if (err.message.includes('SERVICE_NOT_AVAILABLE')) {
                const connectionListener = () => {
                    this.initializeFirebase(this.user);
                    window.removeEventListener('online', connectionListener);
                };
                window.addEventListener('online', connectionListener);
            }
        });
    }

    /**
     * Get last notification that on tap, opened the app.
     * Payload will be null if the app was opened normally.
     */
    public getInitialPushPayload() {
        this.logger.trace(`getInitialPushPayload()`);
        return this.fcm.getInitialPushPayload();
    }

    private async updateToken(token: string): Promise<void> {
        this.logger.debug(`updateToken() token = ${token}`);
        if (token){
            try {
                await this.sendTokenToServer(token);
            } catch (e) {
                this.logger.phic.error('Failed to submit firebase token', e);
            }
        } else {
            this.logger.debug(`Firebase token is not available`);
        }
    }

    /**
     * Listen for Firebase notifications and broadcast the appropriate event
     */
    buildNotificationHandler(): void {
        this.logger.phic.debug('buildNotificationHandler');
        if (this.onNotificationSubscription) return;
        this.onNotificationSubscription = this.firebaseMessagingService.notification$.subscribe((data: INotificationPayload) => {
            this.logger.debug(`fcm.onNotification(): ${JSON.stringify(data, null, '\t')}`);
            if (data.jsonData) data = {data, ...JSON.parse(data.jsonData)};
            if (data.data && typeof data.data === 'string') data.data = JSON.parse(data.data);
            if (data.type.includes('video')) {
                if (data.action === 'incoming_call') {
                    this.communication.incomingVideoCall.next(data);
                } else if (data.action === 'call_unanswered' || data.action === 'call_declined') {
                    this.communication.endVideoCall.next(data);
                } else if (data.action === 'call_left') {
                    this.communication.callerLeft.next(data);
                }
            } else if (data.type === 'chat') {
                this.communication.newChatMessage.next(data);
            } else if (data.type === 'voice' || data.type === 'voicecall') {
                if (data.action === 'call_left') {
                    this.communication.callerLeft.next(data.data);
                } else {
                    this.communication.incomingVoiceCall.next(data);
                }
            }
        }, (error) => {
            this.logger.phic.error('Failed to initialize firebase notification listener', error);
        });
        this.tokenRefresh();
    }

    tokenRefresh(): void {
        this.logger.trace(`tokenRefresh()`);
        if (this.onTokenRefreshSubscription) {
            this.logger.trace(`tokenRefresh() subscription already active`);
            return;
        }
        this.onTokenRefreshSubscription = this.firebaseMessagingService.tokenRefresh$.subscribe((token: string) => {
            const shouldUpdate = this.shouldUpdateToken;
            this.logger.trace(`fcm.onTokenRefresh() -> token = ${token}, shouldUpdate = ${shouldUpdate}`);
            if (shouldUpdate) {
                this.updateToken(token);
            }
        });
    }

    /**
     * Store Firebase device token
     * @param token
     */
    sendTokenToServer(token: string): Observable<any> {
        const data = {
            data: {
                value: token,
                hrsid: User.hrsid,
                deviceType: 'mobile'
            },
        };

        const post = this.gatewayApi.post(
            'push-tokens',
            data,
        ).pipe(
            share()
        );

        post.subscribe({
            next: (res) => {
                this.logger.phic.log('Successfully stored firebase device token');
            },
            error: (err) => {
                this.logger.phic.error('Failed to store firebase device token');
            }
        });

        return post;
    }

    public deleteInstanceId(): void {
        this.logger.debug(`deleteInstanceId()`);
        this.user = null;
        this.shouldUpdateToken = false;
        // prevents signed out PCM users from receving notifications
        this.fcm.deleteInstanceId().then(()=>{
            this.logger.phic.debug('Firebase instance successfully deleted');
        }, (err) => {
            this.logger.phic.error('Failed to delete firebase instance' + err);
        });
    }
}
