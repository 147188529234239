import {AdminService} from './admin/admin.service';
import {Settings} from './settings/settings';
import {User} from './user/user';
import {FirebaseNotifications} from './firebase/firebase';
import {PinEntryService} from './pinEntry/pinEntry.service';
import {PatientProfileService} from './patientProfile/patientProfile.service';
import {CommunicationService, ModalService} from '@hrs/providers';
import {UserAgentProvider} from './user-agent/user-agent';
import {ZoomService} from './zoom/zoom.service';

export {
    AdminService,
    CommunicationService,
    FirebaseNotifications,
    PinEntryService,
    PatientProfileService,
    Settings,
    User,
    ModalService,
    UserAgentProvider,
    ZoomService
};
