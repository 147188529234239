import {Component, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ModalController, NavParams, LoadingController, Platform, IonContent, AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {CommunicationService, ModalService, User, ZoomService} from '@caregiver/providers';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {Dialogs} from '@ionic-native/dialogs/ngx';
import {Subscription} from 'rxjs';
import {VideoNotification} from '../../services/firebase/firebase-data.interface';
import {PermissionService} from 'src/app/services/permissions/permission.service';
import {Device} from '@ionic-native/device/ngx';
import {getLogger} from '@hrs/logging';

declare var OT:any;
declare var cordova: any;

export interface VideoCallLeftData {
    callId: string,
    id: string
}

@Component({
    selector: 'app-video',
    templateUrl: './video.page.html',
    styleUrls: ['./video.page.scss'],
})
export class VideoPage {
  private readonly logger = getLogger('VideoPage');
  session: any;
  publisher: any;
  apiKey: any;
  backAction: any;
  sessionId: string;
  token: string;
  userHrsId: string;
  callId: string;
  calling: boolean;
  callStatus: string;
  modalClosing: boolean;
  callData: any;
  endCallEvent: Subscription;
  private callerLeft: Subscription;
  exitCallEnterNewEvent: Subscription;
  private callTimeout: ReturnType<typeof setTimeout>;
  @ViewChild(IonContent, {}) content: IonContent;

  constructor(
      private user: User,
      private platform: Platform,
      private communication: CommunicationService,
      private alertCtrl: AlertController,
      private loadingCtrl: LoadingController,
      private translateService: TranslateService,
      private navParams: NavParams,
      private modalCtrl: ModalController,
      private ref: ChangeDetectorRef,
      private screenOrientation: ScreenOrientation,
      private dialogs: Dialogs,
      private modalService: ModalService,
      private zoomService: ZoomService,
      private permissionService: PermissionService,
      public device: Device
  ) {
      this.callData = this.navParams.get('callData');
      this.callId = this.callData.callId || '';
      this.modalService.setModalStatus('VideoCallModalPage', true);
      this.apiKey = '';
      this.sessionId = '';
      this.token = '';
      this.userHrsId = User.hrsid;
  }

  ngOnInit() {
      if (this.platform.is('cordova')) {
          // lock screen in portrait mode
          this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      }
      // prevent back button from closing modal without ending call
      this.backAction = this.platform.backButton.subscribeWithPriority(1, () => {
          this.dismiss();
      });
      this.permissionService.checkVideoCallPermissions(Number(this.device.version));
      // if the modal is initialized with a callId we are receiving a call, begin ringing
      if (this.callId) {
          this.callStatus = 'RINGING';
          // this.ringtones.getRingtone();
          // this.ringtones.playRingtone(add ringtone file uri here):
      }
      // if left another call to answer, answer once the modal is open
      if (this.callData.answer) {
          this.initializeVideoCall();
      }

      this.initNotificationListeners();
  }

  ngOnDestroy() {
      // removes hardware back button handler
      if (this.backAction) this.backAction.unsubscribe();
      if (this.endCallEvent) this.endCallEvent.unsubscribe();
      if (this.exitCallEnterNewEvent) this.exitCallEnterNewEvent.unsubscribe();
      if (this.platform.is('cordova')) {
          this.screenOrientation.unlock();
      }
      if (this.callerLeft) this.callerLeft.unsubscribe();
      if (this.callTimeout) {
          clearTimeout(this.callTimeout);
      }
  }

  /**
   * Subscribe to communications events
   */
  initNotificationListeners() {
      // the person we we're calling missed or ignored the call
      this.endCallEvent = this.communication.endVideoCall$.subscribe((data: VideoNotification) => {
          this.onCallMissed(data);
      });

      this.callerLeft = this.communication.callerLeft$.subscribe((data: VideoCallLeftData) => {
          // if the call has not been answered and the callid's match, end the call - called when the call is still ringing but the caller has hung up
          if (!this.calling && this.callData.callId === data.callId) {
              this.endCall(true, true, true);
          }
      });

      // End current call and join incoming call
      // event listener, call in progress, call pending in background -- should be able to remove once native ui is implemented
      this.exitCallEnterNewEvent = this.communication.exitVideoCallEnterNew$.subscribe(() => {
          this.onNewCommunication();
      });
  }

  // callback for event listened, call missed or call ignored
  private onCallMissed(data: VideoNotification): void {
      if (
          data &&
          data.callId == this.callId &&
          (data.action === 'call_unanswered' || data.action === 'call_declined')
      ) {
          const displayStatuses = {
              'call_unanswered': 'CALL_UNAVAILABLE',
              'call_declined': 'CALL_DECLINED'
          };
          if (this.session) {
              // Allow user to retry call if OpenTok.
              this.allowRetryCall(displayStatuses[data.action]);
          } else {
              // Because the modal closes after we enter Zoom's call UI, just end the call.
              this.endCall();
          }
      }
  }
  // callback for event listener, call in progress, call pending in background -- should be able to remove once native ui is implemented
  onNewCommunication() {
      this.endCall();
  }

  // callback for event listener, user has left meeting successfully
  private onCallLeft(): void {
      this.communication.updateParticipantStatus('left');
      cordova.plugins.Zoom.removeMeetingLeaveListener();
  }

  /**
   * Toggle button between start call/ end call
   */
  toggleCall() {
      if (this.calling) {
          this.endCall();
      } else {
          if (!this.modalClosing) {
              this.initializeVideoCall();
          }
      }
  }

  /**
   * Create a call or accept an incoming call
   */
  initializeVideoCall() {
      this.calling = true;
      this.callStatus = 'CONNECTING';
      this.ref.detectChanges();

      if (this.callId) {
      // incoming call
          this.getVideoCallToken();
      } else {
      // starting a call
          this.getCallId();
      }
  }

  /**
   * Creates a call by requesting a callId
   * on success starts a request for the call Token
   */
  getCallId() {
      // tablet rings on success
      this.communication.getVideoCallId(this.userHrsId, this.callData.patientHrsId).subscribe(
          {
              next: (res: any) => {
                  this.callId = res.data.id;
                  this.getVideoCallToken();
              },
              error: (err) => {
                  this.calling = false;
                  this.logger.phic.error('Error: ', err);
                  this.errorPlacingCall();
              }
          }
      );
  }

  /**
   *  Requests token, api key, and sessionId for entering the call room
   */
  getVideoCallToken() {
      this.communication.getVideoCallToken(this.callId, this.userHrsId).subscribe(
          {
              next: (res: any) => {
                  const jwtToken = res.data.value;
                  this.apiKey = res.data.projectKey;
                  this.sessionId = res.data.sessionId;
                  this.token = res.data.value;
                  this.callData = {...this.callData, ...res.data};
                  this.communication.videoParticipantId = res.data.id;

                  if (res.data.provider === 'zoom') {
                      this.zoomService.initWithJWTToken(jwtToken).then((message) => {
                          this.logger.phic.log('Successfully initialized zoom, now will join meeting');
                          this.enterZoomVideoCall(this.callData.sessionId, this.callData.password);
                      }).catch((err) => {
                          this.logger.phic.error('Zoom is still not initialized thus not placing the call' + err);
                      });
                  } else {
                      if (this.permissionService.hasVideoCallPermissions || this.platform.is('ios')) {
                          this.enterOpenTokVideoCall();
                      } else {
                          this.logger.phic.error('Camera or microphone permission not allowed');
                      }
                  }
              },
              error: (err) => {
                  this.calling = false;
                  this.logger.phic.error('Error: ', err);
                  this.errorPlacingCall();
              }
          }
      );
  }

  private enterZoomVideoCall(meetingNumber: string, password: string): void {
      // Note the display name is just in english because we don't know what language the recipient would want to see it in
      this.zoomService.joinMeeting(meetingNumber, password, 'Caregiver').then(
          () => {
              cordova.plugins.Zoom.addMeetingLeaveListener(this.onCallLeft.bind(this), this);
              this.communication.updateParticipantStatus('active');
              // Zoom call was joined successfully. Since the Zoom call opens a separate page, when you end the call
              // there's no reason to still have our video modal still open behind it and have an extra step to close that,
              // so go ahead and close it here.
              setTimeout(() => {
                  this.callStatus = 'CALL_ENDED';
                  this.calling = false;
                  this.dismiss();
              }, 3000);
          },
          () => {
              this.callStatus = 'CALL_FAILED';
          }
      );
  }

  /**
   * Enter call and wait for answer event
   * Also handles end call event after the person we are calling accepts
   */
  enterOpenTokVideoCall() {
      if (!this.session) {
          this.resetPublisherFeed();
          this.session = OT.initSession(this.apiKey, this.sessionId);
          this.publisher = OT.initPublisher('videoCallPublisher');
          this.initOpenTokEventListeners();
          this.connectOpenTokSession();
          this.timeoutCall();
      } else {
          this.logger.phic.log('Not initializing session again as Open tok session already exists');
      }
  }

  private resetPublisherFeed(): void {
      // When an active call is destroyed, the publisher div is stripped of all classes and stylings.
      // We need to manually add our custom styles.
      const publisherFeed = document.getElementById('videoCallPublisher');
      publisherFeed.classList.add('c_call--view-caller');
      publisherFeed.style.width = '25vw';
      publisherFeed.style.height = '20vh';
      publisherFeed.style.position = 'absolute';
      publisherFeed.style.bottom = '13vh';
      publisherFeed.style.right = '1em';
      publisherFeed.style.zIndex = '1000';
      publisherFeed.style.pointerEvents = 'none';
  }

  private timeoutCall(): void {
      // JIR-9499: Match CC2 functionality that ends the outgoing call attempt after 90s.
      this.callTimeout = setTimeout(() => {
          this.communication.getVideoCallStatus(this.callId).subscribe(
              {
                  next: (res) => {
                      const status = res.data.status;
                      const displayStatuses = {
                          'missed': 'CALL_UNAVAILABLE',
                          'declined': 'CALL_DECLINED',
                          'ready': 'CALL_UNAVAILABLE'
                      };
                      // Keep modal up and update UI with new call status and option to retry call.
                      if (displayStatuses[status]) {
                          this.allowRetryCall(displayStatuses[status]);
                      }
                  },
                  error: (err) => {
                      this.logger.phic.error(err);
                      // End call and dismiss the modal if status can not be verified.
                      if (this.session) { // OT
                          this.endCall();
                      }
                  }
              }
          );
      }, 90 * 1000);
  }

  /**
     * End call and update modal to show Unavailable or Declined status and Retry button.
     */
  private allowRetryCall(status: string): void {
      if (this.session) { // OT
          this.endCall(false, false);
          OT.updateViews();
      }
      this.callStatus = status;
      this.modalClosing = false;
      this.ref.detectChanges();
  }

  initOpenTokEventListeners() {
      this.session.on({
          streamCreated: (event) => {
          // callee answered
              this.callStatus = 'CALL_CONNECTED';
              this.ref.detectChanges();
              // displays callees stream
              this.session.subscribe(event.stream, 'videoCallSubscriber', {width: '100%', height: '81vh'});
              OT.updateViews();
          },
          streamDestroyed: (event) => {
              // the person the user is calling hung up
              // only happens when the call was answered
              this.endCall();
              this.logger.phic.log(`Stream ${event.stream.name} ended because ${event.reason}`);
              OT.updateViews();
          },
          sessionConnected: (event) => {
              this.session.publish(this.publisher);
              this.communication.updateParticipantStatus('active');
          }
      });
  }

  connectOpenTokSession() {
      this.session.connect(this.token, (error) => {
          if (error) {
              this.logger.phic.error('Error connecting opentok session', error);
          }
      });
  }

  /**
    * End call and clean up
    */
  private endCall(dismiss: boolean = true, updateStatus: boolean = true, callLeft: boolean = false): void {
      this.calling = false;
      this.callId = null;
      if (this.session) {
          // end connected calls
          if (updateStatus) {
              this.callStatus = 'CALL_ENDED';
          }
          this.session.disconnect();
          this.session = null;
          this.publisher = null;
          this.callId = null;
          this.apiKey = null;
          this.sessionId = null;
          this.token = null;
          this.callData = {
              patientHrsId: this.callData.patientHrsId,
              patientName: this.callData.patientName
          };
          this.communication.updateParticipantStatus('left');
          this.communication.videoParticipantId = null;
      }
      this.ref.detectChanges();

      if (dismiss) {
          // disable the call button so we don't accidentally start a new call with the modal closed
          this.modalClosing = true;
          this.dismiss(callLeft);
      }
  }

  /**
   * Dismiss modal
   * prompt user first in the case that they are already on a call
   */
  dismiss(callLeft: boolean = false) {
      if (this.calling) {
      // user tried to close the modal during a call
          this.showCloseModalPrompt();
      } else if (this.callStatus === 'RINGING' && !callLeft) {
      // user exited modal while incoming call was ringing
          this.callStatus = 'CALL_DECLINED';
          this.communication.updateParticipantStatus('declined');
      } else {
      // call has ended close modal
          this.modalClosing = true;
          this.modalService.setModalStatus('VideoCallModalPage', false);
          this.modalCtrl.dismiss();
      }
  }

  /**
   * Show alert if call fails
   */
  async errorPlacingCall() {
      this.callStatus = 'CALL_FAILED';
      let alert = await this.alertCtrl.create({
          header: this.translateService.instant('ERROR_TITLE'),
          message: this.translateService.instant('VIDEO_CALL_ERROR'),
          buttons: [
              {
                  text: this.translateService.instant('CANCEL_BUTTON'),
                  role: 'cancel',
                  handler: () => {}
              }, {
                  text: this.translateService.instant('RETRY_BUTTON'),
                  handler: () => {
                      this.initializeVideoCall();
                  }
              }
          ]
      });
      return await alert.present();
  }

  /**
   * Prompt user whether to end call and exit modal or continue call
   */
  showCloseModalPrompt() {
      // native dialog bc ionic alert would not display over video
      this.dialogs.confirm(
          this.translateService.instant('END_CALL_MESSAGE'),
          this.translateService.instant('END_CALL_TITLE'),
          [
              this.translateService.instant('END_CALL'),
              this.translateService.instant('CONTINUE')
          ]
      ).then((e: any)=>{
      // if e is 1 the user clicked End Call
      // e refers to the selections index in the buttonLabels array
      // this index in this case starts at 1
          if (e && e === 1) {
              this.endCall();
          }
      });
  }
}
