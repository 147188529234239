<ion-header>
    <ion-toolbar class="c_app_header is-modal">
        <ion-buttons class="c_app_header--buttons" slot="start">
            <ion-button class="c_app_header--btn" (click)="dismiss()">
                <ion-icon class="c_app_header--icon-back"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class="c_app_header--title">
            <span class="c_app_header--text">
            {{ 'CHAT_PANEL' | translate }}
            </span>
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <ion-list class="c_chat--list">
        <section class="c_chat" *ngFor="let message of messages; let last = last" no-lines>
            <article class="c_chat--message ion-text-right" *ngIf="message.hrsid === userHrsId">
                <article class="c_chat--bubble-right">
                    <span class="c_chat--msg_name">{{ 'CHAT_YOU' | translate }}</span>
                    <span class="c_chat--msg_text">{{ message.message }}</span>
                </article>
            </article>
            <div class="c_chat--timestamp-right ion-text-wrap" *ngIf="message.hrsid === userHrsId">{{ message.createdAt ? convertTimeAndDate(message.createdAt) : message.messageStatus }}</div>
            <article class="c_chat--message ion-text-left" *ngIf="message.hrsid != userHrsId">
                <div class="c_chat--bubble-left">
                    <span class="c_chat--msg_name">{{ 'CHAT_PATIENT' | translate }}</span>
                    <span class="c_chat--msg_text">{{ message.message }}</span>
                </div>
            </article>
            <article class="c_chat--timestamp-left ion-text-wrap" *ngIf="message.hrsid != userHrsId">
                {{ convertTimeAndDate(message.createdAt) }}
            </article>
            {{last && !polling ? callScrollFunction() : ''}}
        </section>
    </ion-list>
</ion-content>

<ion-footer class="c_chat--footer">
    <form [formGroup]="data"  (submit)="sendTextMessage()" class="c_chat--form">
        <ion-textarea class="c_chat--input" type="text" placeholder="{{ 'CHAT_MESSAGE' | translate }}" formControlName="message"></ion-textarea>
        <ion-button class="c_chat--btn-send" type="submit" [disabled]="data.value.message == ''">
            <ion-icon class="c_chat--icon-send"></ion-icon>
            {{ 'SEND' | translate }}
        </ion-button>
    </form>
</ion-footer>

