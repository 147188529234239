import {Component, ViewChild} from '@angular/core';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {TranslateService} from '@ngx-translate/core';
import {
    Platform,
    MenuController,
    NavController,
    AlertController,
    ToastController,
    IonContent,
    LoadingController
} from '@ionic/angular';
import * as moment from 'moment';
import {GatewayService} from '@hrs/gateway';
import {
    BroadcastService,
    GatewayApi,
    GlobalSettingsService,
    HRSLoggerMonitor,
    TokenService
} from '@hrs/providers';
import {environment} from '@app/env';
import {CallNumber} from '@ionic-native/call-number/ngx';

import {
    Settings,
    User,
    FirebaseNotifications,
    UserAgentProvider,
    AdminService,
} from '@caregiver/providers';
import {BuildUtility} from '@hrs/utility';
import {AndroidPermissionResponse, AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {Device} from '@ionic-native/device/ngx';
import {firstValueFrom} from 'rxjs';
import {PermissionService} from './services/permissions/permission.service';
import {getLogger, primaryTransport} from '@hrs/logging';
import {FirebaseMessagingService} from './services/firebase/firebase-messaging.service';
import {HRSStorageService} from './services/storage/hrs-storage.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    private readonly logger = getLogger('AppComponent');
    rootPage = null;
    modalState: any;
    versionNumber: string;
    isDisplaying: boolean = false; // toast for forced log outs
    deviceVersion: any;

    @ViewChild(IonContent, {}) content: IonContent;

    pages: any[] = [
        {title: 'Welcome', component: 'welcome'},
        {title: 'PIN Entry', component: 'pin-entry'},
        {title: 'Profile', component: 'profile'},
        {title: 'Login', component: 'login'},
        {title: 'Sign Up', component: 'signup'}
    ];

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private appVersion: AppVersion,
        private translate: TranslateService,
        private settings: Settings,
        private loggerMonitor: HRSLoggerMonitor,
        private user: User,
        private callNumber: CallNumber,
        private alertCtrl: AlertController,
        private toastCtrl: ToastController,
        private tokenService: TokenService,
        private firebase: FirebaseNotifications,
        private gatewayApi: GatewayApi,
        private gateway: GatewayService,
        private globalSettingsService: GlobalSettingsService,
        private userAgentProvider: UserAgentProvider,
        private broadCastService: BroadcastService,
        private menuCtrl: MenuController,
        private navCtrl: NavController,
        private loadingCtrl: LoadingController,
        private storage: HRSStorageService,
        private adminService: AdminService,
        private androidPermissions: AndroidPermissions,
        private device: Device,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private firebaseMessagingService: FirebaseMessagingService
    ) {
        this.initializeApp();
    }

    initializeApp() {
        primaryTransport.enableDefaultBroadcast();
        this.loggerMonitor.init('cgmobile');

        this.initDeviceVersion();
        this.platform.ready().then(async () => {
            this.firebaseMessagingService.initialize().catch((e) => {
                this.logger.phic.warn(`failed to initialize FirebaseMessagingService: ${e}`);
            });
            await this.userAgentProvider.getUserAgentInfo();
            this.logger.phic.log(environment.current_environment);
            this.settings.load().finally(async () => {
                // Check for any domain changes because app will lose those changes on force kill.
                // NOTE: We are only using ionic/storage for domain persistence because we do not need encryption for this data.
                //  Do not use this storage package unless otherwise approved by Joe or Sara.
                await this.storage.get('updatedDomain').then((updatedDomain) => {
                    if (updatedDomain) {
                        BuildUtility.setDomain(updatedDomain).then((domain) => {
                            this.gatewayApi.url = domain;
                            this.tokenService.url = domain;
                        }).catch((err) => {
                            this.logger.phic.error(err);
                        });
                    }
                });

                if (this.platform.is('cordova')) {
                    const initialSetupComplete = this.settings.getValue('initialSetupComplete');
                    if (!initialSetupComplete) {
                        if (this.platform.is('ios')) {
                            this.firebase.askIOSPushPermission();
                        } else {
                            this.firebase.createAndroidNotificationChannel();
                        }
                        this.settings.setValue('initialSetupComplete', true);
                    }

                    // Firebase account switching is currently an Android only feature.
                    // Due to the stricter nature of Firebase iOS configuration, we are only able to reconfigure Firebase Apps at runtime for Android.
                    // Check the platform for Android and configure Firebase to the account associated with the current domain.
                    if (this.platform.is('android')) {
                        this.firebase.setFirebaseAccount(BuildUtility.getDomainName() === 'prod' ? 'prod' : 'test').catch((err) => {
                            this.logger.phic.error(err);
                        });
                    }
                }
                this.populateVersionNumber();
                this.initTranslate().then(() => {
                    if (this.platform.is('android')) {
                        this.permissionService.checkVideoCallPermissions(this.getDeviceVersion()).then(() => {
                            this.checkAndroidPermission(['android.permission.POST_NOTIFICATIONS'], 'ANDROID_NOTIFICATION_PERMISSION_TITLE', 'ANDROID_NOTIFICATION_PERMISSION_MESSAGE').then(() => { // we want permissions to be asked for one after the other
                                this.checkAndroidPermission(['android.permission.READ_MEDIA_IMAGES', 'android.permission.READ_MEDIA_VIDEO', 'android.permission.READ_MEDIA_AUDIO'],
                                    'ANDROID_MEDIA_PERMISSION_TITLE', 'ANDROID_MEDIA_PERMISSION_MESSAGE');
                            });
                        });
                    }
                });
                this.menuCtrl.swipeGesture(false);
                // persist login if tokens are still valid
                this.user.loadFromSettings(this.settings);
                const token = this.settings.getValue('token');
                const refresh = this.settings.getValue('refresh');
                if (token) {
                    this.tokenService.storeTokens({
                        attributes: {
                            token: token,
                            refresh: refresh
                        }
                    });
                }
                if (User.isLoggedIn()) {
                    this.globalSettingsService.loadGlobalSettings();
                    this.user.getPatients().subscribe((res: any) => {
                        if (res.data.length > 0) {
                            this.navCtrl.navigateRoot('/patient');
                        } else {
                            this.navCtrl.navigateRoot('/pin-entry');
                        }
                    });
                    if (this.platform.is('cordova')) {
                        this.firebase.initializeFirebase(this.user);
                        if (this.platform.is('ios')) {
                            // style ios clock with black font
                            this.statusBar.styleDefault();
                        }
                    } else {
                        this.rootPage = 'welcome';
                    }
                } else {
                    this.navCtrl.navigateRoot('/welcome');
                }

                if (this.platform.is('cordova')) {
                    // iOS related source code has been completely removed from cordova-plugin-splashscreen
                    // since it has been integrated into the core of the Cordova-iOS 6.x platform.
                    if (this.platform.is('ios')) {
                        (navigator as any).splashscreen.hide();
                    } else {
                        this.splashScreen.hide();
                    }
                }
            });

            this.broadCastService.interceptorLogout.asObservable().subscribe((values) => {
                this.logger.phic.debug('Observing Interceptor', values);
                if (values) {
                    this.loadingCtrl.getTop().then((element) => {
                        if (element) {
                            this.loadingCtrl.dismiss();
                        }
                    });

                    if (!this.isDisplaying && values.error) {
                        this.isDisplaying = true;
                        setTimeout(() => {
                            this.isDisplaying = false;
                        }, 2000);
                        // error.message is compared to server error response from HRSAPIV2/cgc-patients route
                        if (values.error.message === 'LOGIN_ERROR.CONCURRENT_SESSIONS') {
                            this.forcedLogoutToast('SIGN_IN_ERROR.CONCURRENT_SESSIONS');
                        } else {
                            this.forcedLogoutToast();
                        }
                    }
                    this.user.logout();
                }
            });
        });
    }

    public checkAndroidPermission(permission: string[], permissionAlertTitle: string, permissionMessage: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            if (this.getDeviceVersion() > 12) {
                let requiredPermissions: string[] = permission;
                let missingPermissions: string[] = [];
                for (const requiredPermission of requiredPermissions) {
                    await this.checkForPermission(requiredPermission).then((result) => {
                        if (!result.hasPermission) {
                            missingPermissions.push(requiredPermission);
                        }
                    });
                }
                if (missingPermissions && missingPermissions.length > 0) {
                    {
                        let alert = await this.alertCtrl.create({
                            header: this.translateService.instant(permissionAlertTitle),
                            message: this.translateService.instant(permissionMessage),
                            buttons: [
                                {
                                    text: this.translateService.instant('CLOSE_BUTTON'),
                                    handler: () => {
                                        this.androidPermissions.requestPermissions(missingPermissions).then(
                                            (result) => {
                                                resolve();
                                            }, (error) => {
                                                this.logger.phic.error('Error requesting permission from Android' + error);
                                                reject(error);
                                            }
                                        );
                                    }
                                }
                            ]
                        });
                        return await alert.present();
                    }
                } else {
                    resolve();
                }
            } else {
                resolve();
            }
        });
    }

    public async checkForPermission(permission: string): Promise<AndroidPermissionResponse> {
        return this.androidPermissions.checkPermission(permission);
    }

    /**
 * @returns Quering the device version from Device plugin if it is not initialized yet
 */
    public getDeviceVersion(): number {
        if (!this.deviceVersion) {
            this.initDeviceVersion();
        }
        return this.deviceVersion;
    }

    /**
     * Initializing the device version
     */
    private initDeviceVersion(): void {
        const devVersion = Number(this.device.version);
        if (!Number.isNaN(devVersion)) {
            this.deviceVersion = devVersion;
        }
    }

    private async initTranslate() : Promise<any> {
        // English will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');

        // Apply the user's preferred language
        let language = this.translate.getBrowserLang() || 'en';
        // this.translate.use(language);
        await firstValueFrom(this.translate.use(language));
        moment.locale(language);
        this.gatewayApi.language = language;
        this.gateway.language = language;
        this.settings.setValue('language', language);
    }

    /**
    * Display reason for getting a forced 401 or 302 error from the api-interceptor
    * @param message
    */
    async forcedLogoutToast(message?: string): Promise<any> {
        let text = message || 'SIGN_IN_FORCED_LOGOUT';
        let toast = await this.toastCtrl.create({
            message: this.translate.instant(text),
            cssClass: 'toast-fail',
            position: 'top',
            buttons: [
                {
                    text: this.translate.instant('CLOSE_BUTTON'),
                    role: 'cancel'
                }
            ]
        });
        return await toast.present();
    }

    /**
     * Whenever the user is logged out, redirects to LoginPage
     */

    logoutClicked() {
        this.logger.phic.debug('Logout User');
        this.user.logout();
    }

    public openAdmin(): void {
        this.adminService.openAdmin();
    }

    initSupportCall() {
        let supportNumber = '908-280-0420';

        if (this.platform.is('ios')) {
            // ios shows a prompt natively
            this.dialPhoneNumber(supportNumber);
        } else {
            // android: add a prompt to prevent accidental calling
            this.showSupportCallPrompt(supportNumber);
        }
    }

    async showSupportCallPrompt(supportNumber) {
        let prompt = await this.alertCtrl.create({
            header: supportNumber,
            buttons: [
                {
                    text: this.translate.instant('CANCEL_BUTTON'),
                    handler: () => {}
                }, {
                    text: this.translate.instant('DIAL'),
                    handler: () => {
                        this.dialPhoneNumber(supportNumber);
                    }
                }
            ]
        });
        return await prompt.present();
    }

    async dialPhoneNumber(phoneNumber) {
        this.callNumber.callNumber(phoneNumber, true)
            .then((res) => {
                // maybe we should log when people call support
            })
            .catch((err) => {
                this.phoneNumberToast();
            });
    }

    async phoneNumberToast() {
        let toast = await this.toastCtrl.create({
            message: this.translate.instant('CALL_FAIL'),
            duration: 3000,
            position: 'top'
        });
        return await toast.present();
    }

    populateVersionNumber() {
        if (this.platform.is('cordova')) {
            this.appVersion.getVersionNumber().then((versionNumber) => {
                if (versionNumber) {
                    this.versionNumber = 'v' + versionNumber;
                }
            });
        } else {
            this.versionNumber = 'v1.0.0';
        }
    }
}
