import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';

@Injectable({
    providedIn: 'root'
})
export class HRSStorageService {
    private mStorage: Storage;
    private mStorageReady: Promise<any>;

    constructor(
        private readonly storageCreator: Storage
    ) {
        this.mStorageReady = this.init();
    }

    private async init(): Promise<void> {
        this.mStorage = await this.storageCreator.create();
    }

    public ready(): Promise<any> {
        return this.mStorage ? Promise.resolve() : this.mStorageReady;
    }

    public async get(key: string): Promise<any> {
        await this.ready();
        return this.mStorage.get(key);
    }

    public async set(key: string, value: any): Promise<any> {
        await this.ready();
        return this.mStorage.set(key, value);
    }

    public async remove(key: string): Promise<any> {
        await this.ready();
        return this.mStorage.remove(key);
    }
}
