import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth.guard';

const routes: Routes = [
    {path: '', redirectTo: 'patient', pathMatch: 'full'},
    {path: 'welcome', loadChildren: () => import('./welcome/welcome.module').then((m) => m.WelcomePageModule)},
    {path: 'signup', loadChildren: () => import('./signup/signup.module').then((m) => m.SignupPageModule)},
    {path: 'login', loadChildren: () => import('./login/login.module').then((m) => m.LoginPageModule)},
    {path: 'pin-entry', loadChildren: () => import('./pin-entry/pin-entry.module').then((m) => m.PinEntryPageModule)},
    {path: 'patient', canActivate: [AuthGuard], loadChildren: () => import('./patient/patient.module').then((m) => m.PatientPageModule)},
    {path: 'admin', loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule)},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
