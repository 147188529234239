import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CgcPatientApiv2Response, GatewayService} from '@hrs/gateway';

/**
 * This is the service provider for the patient profile
 */
@Injectable({
    providedIn: 'root',
})
export class PatientProfileService {
    constructor(
        public gateway: GatewayService,
    ) {}

    /**
     * Call api to get the patient profile
     */
    getPatientProfile(hrsid: string): Observable<CgcPatientApiv2Response> {
        return this.gateway.get(
            {path: 'apiv2/cgc-patients/' + hrsid}
        );
    }
}
