import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GatewayService, PatientLinkCreationRequest} from '@hrs/gateway';

/**
 * This is the service provider for the patient list API calls
 */
@Injectable({
    providedIn: 'root',
})
export class PinEntryService {
    constructor(
        public gateway: GatewayService,
        public http: HttpClient,
    ) {}

    /**
     * Call api to post Caregiver Patient Pin
     */
    postPin(data: PatientLinkCreationRequest): Observable<any> {
        return this.gateway.post({path: 'patient-links', body: {data}});
    }
}
