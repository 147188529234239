import {Component, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ModalController, NavParams, Platform, IonContent, AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {CommunicationService, ModalService} from '@caregiver/providers';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {Subscription} from 'rxjs';
import {getLogger} from '@hrs/logging';

declare var Twilio: any;

@Component({
    selector: 'app-voice',
    templateUrl: './voice.page.html',
    styleUrls: ['./voice.page.scss'],
})
export class VoicePage {
  private readonly logger = getLogger('VoicePage');
  backAction: any;
  calling: boolean = false;
  callStatus: string;
  patientGender: string;
  riskClass: string;
  callData: any;
  modalClosing: boolean;
  // speaker: boolean = false;
  // mute: boolean = false;

  endCallEvent: Subscription;
  exitCallEnterNewEvent: Subscription;
  @ViewChild(IonContent, {}) content: IonContent;

  constructor(
      private platform: Platform,
      private communication: CommunicationService,
      private translateService: TranslateService,
      private navParams: NavParams,
      private modalCtrl: ModalController,
      private screenOrientation: ScreenOrientation,
      private alertCtrl: AlertController,
      private modalService: ModalService,
      private ref: ChangeDetectorRef
  ) {
      this.callData = this.navParams.get('callData');
      this.modalService.setModalStatus('VoiceCallModalPage', true);
  }

  ngOnInit() {
      if (this.platform.is('cordova')) {
          this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      }
      // add handler so modal isn't closed accidentally during a call by the hardware back button
      this.backAction = this.platform.backButton.subscribeWithPriority(1, () => {
          this.dismiss();
      });

      // incoming call, show ring status
      if (this.callData.callId) {
          this.callStatus = 'RINGING';
      }
      // tapped with app in background
      if (this.callData.answer) {
          this.initializeVoiceCall();
      }

      this.initNotificationListeners();
  }

  ionViewDidLeave() {
      // removes hardware back button handler
      this.backAction.unsubscribe();
      // unsubscribe from listeners
      this.endCallEvent.unsubscribe();
      this.exitCallEnterNewEvent.unsubscribe();

      if (this.platform.is('cordova')) {
          this.screenOrientation.unlock();
      }
  }

  initNotificationListeners() {
      // the person we we're calling missed or ignored the call
      this.endCallEvent = this.communication.endVoiceCall$.subscribe((data: any) => {
          if (
              data &&
              (data.action === 'call_unanswered' || data.action === 'call_declined')
          ) {
              this.endCall();
          }
      });
      // End current call and join incoming call
      this.exitCallEnterNewEvent = this.communication.exitVoiceCallEnterNew$.subscribe(() => {
          this.endCall();
      });
  }

  /**
   *  Toggle button from place call to end call
   */

  toggleCall() {
      if (this.calling) {
          this.endCall();
      } else {
          if (!this.modalClosing) {
              this.initializeVoiceCall();
          }
      }
  }

  /**
   * Start voice call with the patient
   */
  initializeVoiceCall() {
      this.calling = true;
      this.callStatus = 'CONNECTING';
      this.ref.detectChanges();
      if (this.callData.callId) {
          this.acceptIncomingCall();
      } else {
          this.initializeOutgoingCall();
      }
  }

  /**
   * Begins outgoing call
   */
  initializeOutgoingCall() {
      this.communication.initializeOutgoingVoiceCall(this.callData.patientHrsId).subscribe({
          next: (res: any) => {
              this.connectCall(res);
          },
          error: (err) => {
              this.calling = false;
              this.callStatus = 'CALL_FAILED';
              this.ref.detectChanges();
              this.logger.phic.error('Error: ', err);
          }
      });
  }

  /**
   * Get incoming voice call token
   */
  acceptIncomingCall() {
      this.communication.acceptIncomingVoiceCall(this.callData.callId).subscribe({
          next: (res: any) => {
              const data = {callid: this.callData.callId, access: res.access};
              this.connectCall(data);
          },
          error: (err) => {
              this.calling = false;
              this.callStatus = 'CALL_FAILED';
              this.ref.detectChanges();
              this.logger.phic.error('Failed to obtain call token');
          }
      });
  }

  /**
     * Connect to voice call
     * @param data
     */
  connectCall(data) {
      let _this = this;
      this.callStatus = 'CALL_CONNECTED';

      Twilio.TwilioVoiceClient.onClientInitialized(() => {
          this.logger.phic.log('Twilio client initialized.');
          let twilioParams = {
              'To': 'conference:' + data.callid,
              'calltype': 'startcall',
          };
          Twilio.TwilioVoiceClient.call(data.access, twilioParams);
      });

      Twilio.TwilioVoiceClient.initialize(data.access);

      // Handle Errors
      Twilio.TwilioVoiceClient.onError((err) => {
          setTimeout(() => {
              _this.calling = false;
              _this.callStatus = 'CALL_FAILED';
              _this.logger.phic.error('Failed (' + err.message + ')');
              _this.ref.detectChanges();
          }, 1000); // to ensure that error is not overwritten by disconnect
      });

      // Handle Call Connection
      Twilio.TwilioVoiceClient.onCallDidConnect((call) => {
          this.logger.phic.log('Successfully established call');
          setTimeout(() => {
              _this.callStatus = 'CALL_CONNECTED';
              _this.ref.detectChanges();
          }, 0);
      });

      // Handle Call Disconnect
      Twilio.TwilioVoiceClient.onCallDidDisconnect((call) => {
          this.logger.phic.log('Call Ended');
          setTimeout(() => {
              _this.calling = false;
              _this.callStatus = 'CALL_ENDED';
              _this.dismiss();
              _this.ref.detectChanges();
          }, 0);
      });

      Twilio.TwilioVoiceClient.onCallInviteReceived(() => {
          setTimeout(() => {
              _this.calling = true;
              _this.callStatus = 'CALL_CONNECTED';
              _this.ref.detectChanges();
          }, 0);
      });
  }

  /**
   * Stop voice call with the patient
   */
  endCall() {
      if (this.platform.is('cordova')) {
          Twilio.TwilioVoiceClient.disconnect();
      }
      this.callStatus = 'CALL_ENDED';
      this.calling = false;
      this.dismiss();
  }

  async dismiss() {
      if (this.calling) {
          let alert = await this.alertCtrl.create({
              header: this.translateService.instant('END_CALL_TITLE'),
              message: this.translateService.instant('END_CALL_MESSAGE'),
              buttons: [
                  {
                      text: this.translateService.instant('END_CALL'),
                      role: 'cancel',
                      handler: () => {
                          this.endCall();
                      }
                  }, {
                      text: this.translateService.instant('CONTINUE'),
                      handler: () => {}
                  }
              ]
          });
          return await alert.present();
      } else {
          this.modalService.setModalStatus('VoiceCallModalPage', false);
          this.modalClosing = true;
          this.modalCtrl.dismiss();
      }
  }
}
