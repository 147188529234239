import {Component} from '@angular/core';
import {Platform, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {BuildUtility} from '@hrs/utility';
import {FirebaseNotifications, Settings, User} from '@caregiver/providers';
import {GatewayApi, TokenService} from '@hrs/providers';
import {HRSStorageService} from '../../services/storage/hrs-storage.service';
import {getLogger} from '@hrs/logging';

@Component({
    selector: 'domain-settings',
    templateUrl: './domain-settings.component.html',
    styleUrls: ['./domain-settings.component.scss'],
})
export class DomainSettingsComponent {
    private readonly logger = getLogger('DomainSettingsComponent');
    public domains: string[] = BuildUtility.domains.map((domain) => domain.domain);
    public currentDomain: string;
    public selected: string;
    public hrsdomainText: string;

    constructor(
        private toastCtrl: ToastController,
        private translate: TranslateService,
        private user: User,
        private gatewayApi: GatewayApi,
        private firebase: FirebaseNotifications,
        private storage: HRSStorageService,
        private platform: Platform,
        private tokenService: TokenService
    ) {
        this.currentDomain = BuildUtility.getGatewayURL();
        const currentDomainName = BuildUtility.getDomainName();
        this.selected = currentDomainName;
        if (currentDomainName === 'hrsdomain') {
            this.hrsdomainText = this.currentDomain;
        }
    }

    /**
     * Radio button click handler.
     *
     * Only used for HRSDOMAIN because ion-input combined wth ion-label affects the label's listeners.
     * Without this handler, the radio option will not be selected on click of the label.
     */
    public handleLabelClick(option: string): void {
        this.selected = option;
    }

    public setDomain(): void | Error {
        if (this.selected) {
            const userSelection = this.selected === 'hrsdomain' ? this.hrsdomainText : this.selected;
            BuildUtility.setDomain(userSelection).then((updatedDomain) => {
                this.currentDomain = updatedDomain;
                this.presentToast('ADMIN_DOMAIN_SETTINGS.CHANGE_SUCCESS', {domain: updatedDomain}).then(async () => {
                    if (this.platform.is('android')) {
                        this.firebase.setFirebaseAccount(BuildUtility.getDomainName() === 'prod' ? 'prod' : 'test')
                            .catch((err) => this.logger.phic.error(err));
                    }
                    await this.user.logout();
                    // Need to manually update the url variables in GatewayApiService and TokenService because
                    // they access the environment url only once when they are initialized,
                    // unlike GatewayService which accesses the environment url each time an API request is created.
                    this.gatewayApi.url = updatedDomain;
                    this.tokenService.url = updatedDomain;
                    // Store Domain so that the changes are not lost on force-kill.
                    const storeDomain = this.selected === 'hrsdomain' ? BuildUtility.getHRSDomain() : this.selected;
                    this.storage.set('updatedDomain', storeDomain);
                });
            }).catch((err) => {
                this.logger.phic.error(err);
                this.presentToast('ADMIN_DOMAIN_SETTINGS.BAD_DOMAIN');
            });
        } else {
            this.presentToast('ADMIN_DOMAIN_SETTINGS.NO_SELECTION');
        }
    }

    private async presentToast(msg: string, params?: {[key: string]: string}): Promise<void> {
        let domainText = this.translate.instant(msg, params);
        let toast = await this.toastCtrl.create({
            header: domainText,
            duration: 3000,
            position: 'top'
        });

        return await toast.present();
    }
}
