import {Injectable} from '@angular/core';
import {Zoom} from '@ionic-native/zoom/ngx';
import {Platform} from '@ionic/angular';
import {Settings} from '../settings/settings';
import {getLogger} from '@hrs/logging';

declare var cordova: any;

@Injectable({
    providedIn: 'root',
})
export class ZoomService {
    private readonly logger = getLogger('ZoomService');

    constructor(
        private zoom: Zoom,
        private platform: Platform,
        private settings: Settings
    ) {}

    /**
     * Method to initialize Zoom SDK. This needs a JWT token which is associated with a meeting number.
     * Thus before joining a call, we init zoom with this token.
     * @param jwtToken
     */
    public async initWithJWTToken(jwtToken: string): Promise<void> {
        if (this.platform.is('cordova') && jwtToken ) {
            await cordova.plugins.Zoom.initializeWithJWT(jwtToken).then((message) => {
                this.zoom.setLocale(this.settings.getValue('language') || 'en');
                this.logger.phic.log('Initialized zoom sdk with jwt token');
            }).catch((err) => {
                this.logger.phic.error('Error initializing Zoom SDK', err);
            });
        } else {
            this.logger.phic.log('JWT token not present, unable to init zoom');
        }
    }

    joinMeeting(meetingNumber: string, meetingPassword: string, displayName: string) {
        return this.zoom.joinMeeting(meetingNumber, meetingPassword, displayName, {
            'no_dial_in_via_phone': true,
            'no_dial_out_to_phone': true,
            'participant_id': ''
        });
    }
}
