// Angular/Ionic
import {NgModule, ErrorHandler} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {IonicStorageModule} from '@ionic/storage-angular';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

// Native Plugins
import {AppVersion} from '@ionic-native/app-version/ngx';
import {Camera} from '@ionic-native/camera/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {CallNumber} from '@ionic-native/call-number/ngx';
import {Device} from '@ionic-native/device/ngx';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {Dialogs} from '@ionic-native/dialogs/ngx';

// NGX
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CookieService} from 'ngx-cookie-service';
import {Zoom} from '@ionic-native/zoom/ngx';

// Providers
import {FirebaseNotifications, User, Settings, ModalService, CommunicationService, UserAgentProvider} from '@caregiver/providers';
import {GatewayResourceService, GatewayService} from '@hrs/gateway';
import {ApiInterceptor, BroadcastService, GatewayApi, GlobalSettingsService, HRSLoggerMonitor, TokenService} from '@hrs/providers';
import {AuthGuard} from './auth.guard';

// Communication
import {ChatPageModule} from './communication/chat/chat.module';
import {VideoPageModule} from './communication/video/video.module';
import {VoicePageModule} from './communication/voice/voice.module';

// Components
import {AdminModule} from './admin/admin.module';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

// The translate loader needs to know where to load i18n files
// in Ionic's static asset pipeline.
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        AdminModule,
        BrowserModule,
        IonicModule.forRoot({mode: 'ios', backButtonText: ' '}),
        AppRoutingModule,
        HttpClientModule,
        ChatPageModule,
        VideoPageModule,
        VoicePageModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        IonicStorageModule.forRoot(),
    ],
    providers: [
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        AppVersion,
        AndroidPermissions,
        AuthGuard,
        GatewayApi,
        GatewayResourceService,
        GatewayService,
        GlobalSettingsService,
        HRSLoggerMonitor,
        User,
        Camera,
        CallNumber,
        ScreenOrientation,
        Dialogs,
        Device,
        CommunicationService,
        CookieService,
        FirebaseNotifications,
        SplashScreen,
        StatusBar,
        ModalService,
        Settings,
        // Keep this to enable Ionic's runtime error handling during development
        ErrorHandler,
        BroadcastService,
        TokenService,
        {
            provide: HTTP_INTERCEPTORS,
            useFactory: function(broadcastService: BroadcastService, tokenService: TokenService) {
                return new ApiInterceptor(broadcastService, tokenService);
            },
            multi: true,
            deps: [BroadcastService, TokenService]
        },
        UserAgentProvider,
        Zoom
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
