<ion-app>
    <ion-menu contentId="content" type="overlay" side="start" persistant="true" [swipeGesture]="false">
        <ion-header>
            <ion-toolbar class="c_app_menu--header">
                <ion-title class="c_app_menu--title">
                    {{ 'MENU' | translate }}
                </ion-title>
                <ion-menu-toggle class="c_app_menu--btn-close" slot="end">
                    <ion-icon name="close" class="c_app_menu--btn-close-icon"></ion-icon>
                </ion-menu-toggle>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-list class="c_app_menu--list">
                <ion-menu-toggle>
                    <ion-item class="navButton c_app_menu--item"
                              (click)="initSupportCall()"
                              detail-none>
                        <ion-icon slot="start" class="c_app_menu--icon c_app_menu--icon-support"></ion-icon>
                        <ion-label>{{ 'CALL_SUPPORT' | translate }}</ion-label>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle>
                    <ion-item class="navButton c_app_menu--item"
                              (click)="logoutClicked()">
                        <ion-icon slot="start" class="c_app_menu--icon c_app_menu--icon-logout"></ion-icon>
                        <ion-label>{{ 'LOGOUT' | translate }}</ion-label>
                    </ion-item>
                </ion-menu-toggle>
            </ion-list>
        </ion-content>
        <div class="app-version" (click)="openAdmin()">
            {{ versionNumber }}
        </div>
    </ion-menu>
    <ion-router-outlet id="content"></ion-router-outlet>
</ion-app>
