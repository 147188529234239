import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Platform, ToastController, NavController} from '@ionic/angular';
import {GatewayService, PatientLinksRequest, TokenResponse} from '@hrs/gateway';
import {GatewayApi, TokenService} from '@hrs/providers';
import {FirebaseNotifications} from '../firebase/firebase';
import {tap} from 'rxjs/operators';
import {Settings} from '../settings/settings';
import * as jwtDecode from 'jwt-decode';
import {StatusBar} from '@ionic-native/status-bar/ngx';

/**
 * Most apps have the concept of a User. This is a simple provider
 * with stubs for login/etc.
 *
 * This User provider makes calls to our API at the `login` endpoints.
 *
 * If the `status` field is not `success`, then an error is detected and returned.
 */
@Injectable()
export class User {
    _user: any;
    static gatewayToken: any;
    static hrsid: any;
    static patientHrsId: string;
    static supportNumber: any;
    static chatroomId: number;
    sourceApp: string;

    constructor(
        private gatewayApi: GatewayApi,
        private http: HttpClient,
        private firebase: FirebaseNotifications,
        private platform: Platform,
        private settings: Settings,
        private statusBar: StatusBar,
        private tokenService: TokenService,
        private translateService: TranslateService,
        private toastCtrl: ToastController,
        private navCtrl: NavController,
        private gatewayService: GatewayService
    ) {
        this.sourceApp = 'CaregiverConnect Mobile';
        this.tokenService.sourceApp = this.sourceApp;

        this.tokenService.refreshTokenSubject.subscribe((tokenData: TokenResponse) => {
            this.settings.setValue('token', tokenData.attributes.token);
            this.settings.setValue('refresh', tokenData.attributes.refresh);
            User.gatewayToken = tokenData.attributes.token;
            User.hrsid = jwtDecode(User.gatewayToken)['hrsid'];
        });
    }

    static isLoggedIn() {
        return (
            User.gatewayToken &&
            parseInt(jwtDecode(User.gatewayToken)['exp']) > Math.floor(Date.now() / 1000)
        );
    }

    loadFromSettings(settings: Settings) {
        User.gatewayToken = settings.getValue('token');
        if (User.gatewayToken) {
            try {
                User.hrsid = jwtDecode(User.gatewayToken)['hrsid'];
            } catch (e) {
                console.error('Error decoding token', e);
                return;
            }
        }
    }

    /**
     * Send a POST request to our login endpoint
     */
    loginGateway(accountInfo: { user: string, pass: string, type: string }) {
        let credentials = {
            'data': {
                'type': 'credentials',
                'username': accountInfo.user,
                'password': accountInfo.pass,
                'source': this.sourceApp
            }
        };

        return this.gatewayApi.login(credentials)
            .pipe(tap((res: any) => {
                let resData = res.data;
                if (jwtDecode(resData.attributes.token)['type'] !== 'caregiver') {
                    this.errorToast();
                    this.logout();
                    return;
                }

                User.gatewayToken = resData.attributes.token;
                User.hrsid = jwtDecode(User.gatewayToken)['hrsid'];

                this.settings.setValue('token', resData.attributes.token);
                this.settings.setValue('refresh', resData.attributes.refresh);
                this.tokenService.storeTokens(resData);

                if (this.platform.is('cordova')) {
                    this.firebase.initializeFirebase(this);
                }
            }));
    }

    async errorToast() {
        let errorToast = await this.toastCtrl.create({
            message: this.translateService.instant('SIGN_IN_ERROR'),
            duration: 3000,
            position: 'top'
        });
        return await errorToast.present();
    }

    /**
     * Send a POST request to our new caregiver endpoint
     */
    signup(accountInfo: { first: string, last: string, email: string, user: string, pass: string}) {
        let reqBody = {
            data: {
                firstName: accountInfo.first,
                lastName: accountInfo.last,
                identity: {
                    username: accountInfo.user,
                    password: accountInfo.pass,
                    email: accountInfo.email,
                }
            }
        };

        return this.gatewayApi.post('caregivers', reqBody, {Observe: 'response'});
    }

    /**
     * Log the user out, which forgets the session
     */

    public async logout(): Promise<any> {
        User.chatroomId = undefined;
        await this.gatewayApi.logout();
        if (this.platform.is('cordova')) {
            this.firebase.deleteInstanceId();
            if (this.platform.is('ios')) {
                // style ios clock with white font
                this.statusBar.styleLightContent();
            }
        }

        this.navCtrl.navigateRoot('welcome');
    }

    /**
     * Send a GET request for list of caregiver's patients
     */
    getPatients() {
        return this.gatewayService.get({path: 'patient-links?filter[caregiver]=' + User.hrsid})
            .pipe(tap((res: PatientLinksRequest) => {
                // Only set patientHrsId if User has at least one linked Patient
                if (res.data.length > 0) {
                    User.patientHrsId = res.data[0].patient;
                }
            }));
    }
}
