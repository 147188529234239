import {Injectable} from '@angular/core';
import {User} from './services/user/user';

@Injectable()
export class AuthGuard {
    constructor(
        public user: User
    ) {}

    canActivate(): boolean {
        return User.isLoggedIn();
    }
}
