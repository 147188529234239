/**
 * Firebase Account Info.
 *
 * This is the data for our two Firebase accounts. We can manually initialize FirebaseApp in our FCMPlugin using
 * this data.
 *
 * FirebaseAccountInfo.prod: used when domain is pointed to healthrecoverysolutions.com aka prod.
 * FirebaseAccountInfo.test: used when domain is pointed to anything else e.g. staging1, staging2, any of our proxies.
 */

export const FirebaseAccountInfo = {
    prod: {
        'project_number': '848348592935',
        'database_url': 'https://hrsmessaging.firebaseio.com',
        'project_id': 'hrsmessaging',
        'storage_bucket': 'hrsmessaging.appspot.com',
        'app_id': '1:848348592935:android:4caabcf95cbc55fc',
        'api_key': 'AIzaSyAtcchlcA_QQPfWhR7bd7Zx5v7RsgzWmQk',
        'package_name': 'com.hrs.caregiver',
        "client_id": "848348592935-6c4nepokq9t6bk9rnk5j74ocahus44r5.apps.googleusercontent.com",
    },
    test: {
        'project_number': '808741233790',
        'database_url': 'https://test-project-7d531.firebaseio.com',
        'project_id': 'test-project-7d531',
        'storage_bucket': 'test-project-7d531.appspot.com',
        'app_id': '1:808741233790:android:a7788a41ab3145e2',
        'api_key': 'AIzaSyAcCdIQ2egWosksLDG4Iqnd1FCEKtYqIp4',
        'package_name': 'ionic.hrsmobile.byod.caregiver',
        "client_id": "808741233790-su0scsrtt3ql8nn0tbqqe6ep6l88qoor.apps.googleusercontent.com",
    }
}
